
import { computed, defineComponent, ref } from 'vue'
import {
  PageContent,
  UserType,
  UserProfileBanner,
  TileList,
} from '@bd/components'
import { useI18n } from 'vue-i18n'
import { useAppStore } from '@bd/agent/store'
import { useRouter } from 'vue-router'
import { PROFILE_BOTTOM_ITEMS } from '@bd/agent/config'

export default defineComponent({
  components: {
    PageContent,
    UserProfileBanner,
    TileList,
  },

  setup() {
    const { t } = useI18n()
    const store = useAppStore()
    const router = useRouter()
    const userType = ref(UserType.Agent)

    const fetchUser = () => {
      if (!store.state.userProfile?.user.email) {
        store.dispatch('userProfile/userProfile')
      }
    }

    const bottomOptions = {
      iconWrapperColor: 'jungle-green-icon',
      iconClass: 'colorful heliotrope',
    }

    const user = computed(() => {
      return store.state.userProfile?.user
    })

    const onLogoutClick = async () => {
      await store.dispatch('auth/authLogout')
      router.push({ path: '/login' })
    }

    const handleItemClick = (action: string) => {
      if (action === 'logout') {
        onLogoutClick()
      }
    }

    fetchUser()

    return {
      UserType,
      userType,
      t,
      user,
      PROFILE_BOTTOM_ITEMS,
      handleItemClick,
      bottomOptions,
    }
  },
})
